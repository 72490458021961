<template>
  <div class="commonProblem">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->

        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input
          clearable
          placeholder="请输入标题名称"
          v-model="searchParam.content"
          label="标题"
        ></v-input>
        <v-select
          clearable
          :options="optStatusOps"
          v-model="searchParam.optStatus"
          @change="$refs.list.search()"
          placeholder="请选择回复状态"
          label="回复状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="回复" type="text" @click="reply(scope.row)" />
        <v-button
          v-if="scope.row.isRecommend == 0"
          text="置顶"
          type="text"
          @click="isTop(scope.row.id, 1)"
        />
        <v-button
          v-if="scope.row.isRecommend == 1"
          text="取消置顶"
          type="text"
          @click="isTop(scope.row.id, 0)"
        />
        <!-- <v-button text="删除" type="text" @click="deleteUrl(scope.row.id)" /> -->
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getFeedbackInformationList,
  updateFeedbackInformation,
} from "./api.js";
import {
  optStatusOps,
  optStatusMap,
  problemTypesMap,
  problemTypesOps,
  isPlatformShowMap,
  isRecommendMap,
} from "./map.js";

export default {
  name: "commonProblem",
  data() {
    return {
      // 搜索参数
      searchParam: {
        content: "",
        optStatus: "",
      },
      tableUrl: getFeedbackInformationList,
      optStatusOps: optStatusOps(), // 回复状态
      problemTypesOps: problemTypesOps(), // 问题类型
      headers: [
        {
          prop: "feedbackTitle",
          label: "标题",
          width: 400,
        },
        {
          prop: "feedbackType",
          label: "问题类型",
          formatter: (row, prop) => {
            return problemTypesMap[row[prop]] || "--";
          },
        },
        {
          prop: "createTime",
          label: "发布时间",
        },
        {
          prop: "clientName",
          label: "发布者",
        },
        {
          prop: "isPlatformShow",
          label: "是否平台显示",
          formatter: (row, prop) => {
            return isPlatformShowMap[row[prop]] || "--";
          },
        },
        {
          prop: "isRecommend",
          label: "是否置顶",
          formatter: (row, prop) => {
            return isRecommendMap[row[prop]] || "--";
          },
        },
        {
          prop: "optStatus",
          label: "回复状态",
          formatter: (row, prop) => {
            return optStatusMap[row[prop]] || "--";
          },
        },
        {
          prop: "optTime",
          label: "回复时间",
        },
      ],
    };
  },
  components: {},
  mounted() {},
  methods: {
    // 回复
    reply(args) {
      const {
        id,
        feedbackTitle,
        feedbackType,
        feedbackContent,
        isPlatformShow,
        isRecommend,
        optContent,
      } = args;
      this.$router.push({
        name: "commonProblemReply",
        params: {
          id,
          feedbackTitle,
          feedbackType,
          feedbackContent,
          isPlatformShow,
          isRecommend,
          optContent,
        },
      });
    },
    isTop(id, isRecommend) {
      const params = {
        id,
        isRecommend,
      };
      this.$confirm(
        `${isRecommend == 0 ? "是否取消置顶" : "是否置顶"}?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$axios
          .post(`${updateFeedbackInformation}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$refs.list.getList();
            }
          });
      });
    },
    deleteUrl() {
      this.$confirm("是否置顶?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // this.$axios
        //   .post(`${updateFeedbackInformation}`, this.$qs.stringify({ id }))
        //   .then((res) => {
        //     if (res.code === 200) {
        //       this.$refs.list.getList();
        //     }
        //   });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.commonProblem {
  box-sizing: border-box;
  height: 100%;
  .item {
    margin: 10px 0;
    /deep/ .v-input {
      display: flex;
      align-items: center;
    }
  }
}
</style>
